import Axios from "axios";
import { makeUseAxios } from "axios-hooks";

export const axios = Axios.create({
  baseURL: process.env.NODE_ENV === "production" ? "/api" : "",
  headers: { accept: "application/json" },
});

export const useAxios = makeUseAxios({
  axios,
});
