export enum Routes {
  OrderInfoForm = "/",
  ListOrderItems = "/exchange/list-items",
  SelectExchangeItem = "/exchange/select-item",
  SelectQuantity = "/exchange/select-item/select-quantity",
  ExchangeType = "/exchange/select-item/exchange-type",
  SelectCollection = "/exchange/select-item/select-collection",
  SelectProduct = "/exchange/select-item/select-product",
  SelectVariant = "/exchange/select-item/select-variant",
  ReviewItemSelection = "/exchange/select-item/review-item-selection",
  ReviewExchange = "/exchange/select-item/review-exchange",
  ExchangeComplete = "/exchange/thank-you",
}
