import React from "react";
import Loading from "components/Loading";
import { useForm } from "react-hook-form";
import { axios } from "hooks/axios";
import { useExchange } from "components/Exchange/context";

interface IOrderInfoForm {
  email: string;
  orderNumber: string;
}

const OrderInfoForm = () => {
  const {
    register,
    handleSubmit,
    formState,
    reset,
  } = useForm<IOrderInfoForm>();
  const { getExchangeQuery } = useExchange();
  const submit = async (data: IOrderInfoForm) => {
    await axios.post("/v1/exchanges", data);
  };

  if (formState.isSubmitting) {
    return <Loading message="Creating Exchange request" />;
  }

  if (formState.isSubmitSuccessful) {
    return (
      <div className="p-2">
        <div className="text-center py-8">
          Great! We've sent you an email with a link to continue with your
          exchange.{" "}
        </div>
        <button
          className="bg-brand disabled:bg-gray-300 text-white font-semibold px-2 py-3 rounded-lg w-full mt-4"
          onClick={() => reset()}
        >
          Start another exchange
        </button>
      </div>
    );
  }

  if (getExchangeQuery?.loading) {
    return <Loading message="Fetching Order Details" />;
  }

  return (
    <div className="p-2">
      <div className="py-4 text-center text-xl ">
        Enter your order information
      </div>
      <form onSubmit={handleSubmit(submit)} className="flex flex-col">
        <label className="text-brand font-semibold mb-1" htmlFor="orderNumber">
          Order #
        </label>
        <input
          className={`rounded-lg ${
            formState?.errors?.orderNumber && "border border-brand"
          }`}
          type="text"
          placeholder="i.e. 54321"
          {...register("orderNumber", { required: "Order Number is required" })}
        />

        <label className="text-brand font-semibold mb-1 mt-4" htmlFor="email">
          Email
        </label>
        <input
          type="text"
          className={`rounded-lg ${
            formState?.errors?.email && "border border-brand"
          }`}
          placeholder="i.e. hello@dearborndenim.com"
          {...register("email", { required: "Email is required" })}
        />

        <button className="bg-brand disabled:bg-gray-300 text-white font-semibold px-2 py-3 rounded-lg w-full mt-4">
          Submit
        </button>
      </form>
    </div>
  );
};

export { OrderInfoForm as default };
