import React, { useState, useMemo } from "react";
import Loading, { ButtonLoading } from "components/Loading";
import { useExchange } from "components/Exchange/context";
import { LineItemsEntity, Product } from "components/Exchange/types";
import { Switch, Route, Redirect, Link, LinkProps } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { range } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faStar,
  faExclamationCircle,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { useAxios, axios } from "hooks/axios";
import { Routes } from "routes";
import { useEffect } from "react";
import { toast } from "react-toastify";

const CustomLink: React.FC<LinkProps & { disabled: boolean }> = ({
  disabled,
  ...props
}) => {
  return (
    <Link
      {...props}
      className={`${props.className} ${
        disabled ? "pointer-events-none text-gray-500" : "text-blue-500"
      }`}
    />
  );
};

const ExchangeComplete = () => {
  const { reset } = useExchange();
  return (
    <div className="py-16 flex flex-col">
      <p className="mb-4 font-semibold text-center">
        Thank you for submitting your exchange!
      </p>
      <p className="mb-4 text-center">
        You will receive an email confirmation and email update when your order
        ships.
      </p>

      <p className="mb-4 text-center">
        If you have any questions please contact us at{" "}
        <a className="text-brand underline" href="info@dearborndenim.com">
          info@dearborndenim.com
        </a>
      </p>

      <button
        onClick={() => {
          reset();
          window.location.href = "/";
        }}
        className="bg-brand disabled:bg-gray-300 text-white font-semibold px-2 py-3 rounded-lg w-full mt-4 text-center"
      >
        Start over
      </button>
    </div>
  );
};

const SelectProduct: React.FC = () => {
  const { selectingItemFor, setProduct, currentSelection } = useExchange();
  const location = useLocation<{ collection: { id: string } }>();
  const [{ data, loading, error }, refetch] = useAxios<Product[]>({
    url: "/v1/products",
    params: {
      collectionId: location.state.collection.id,
    },
  });
  const productsWithVariantsInPriceRange = useMemo(
    () =>
      data?.filter(
        (p) =>
          (p?.variants?.filter(
            (v) =>
              v?.price &&
              selectingItemFor?.price &&
              v?.price <= selectingItemFor?.price
          )?.length ?? 0) > 0
      ) ?? [],
    [data, selectingItemFor]
  );

  if (loading) {
    return <Loading message="Loading Products" />;
  }

  if (error) {
    return (
      <div className="my-6">
        <div className="text-brand font-semibold text-center">
          Unable to load products
        </div>
        <button onClick={() => refetch()} className="w-full text-blue-500 mt-8">
          Try again
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {productsWithVariantsInPriceRange.length < 1 && (
        <>
          <div className="py-4 text-center text-brand">
            No options available for exchange in this collection
          </div>
          <div className="w-full flex">
            <Link
              to={Routes.SelectCollection}
              className="text-brand flex-1 py-4 text-center active:opacity-50"
            >
              Back
            </Link>
          </div>
        </>
      )}

      <div className="py-4 text-center">Which Product?</div>
      <div className="border-t border-gray-300">
        {productsWithVariantsInPriceRange.map((product) => (
          <div
            key={product?.id}
            onClick={() => {
              if (currentSelection?.product?.id === product?.id) {
                setProduct(null);
              } else {
                setProduct(product);
              }
            }}
            className="border-b border-gray-300 flex flex-row py-2 active:opacity-50 cursor-pointer items-center"
          >
            <div className="w-1/6 text-center">
              <FontAwesomeIcon
                className="text-brand"
                icon={
                  currentSelection?.product?.id === product?.id
                    ? faCheckCircle
                    : faCircle
                }
                size="lg"
              />
            </div>
            <div className="flex-1 text-sm">{product?.title}</div>
          </div>
        ))}
      </div>

      <div className="w-full flex">
        <CustomLink
          disabled={currentSelection?.product === null}
          to={Routes.SelectVariant}
          className="flex-1 py-4 text-center active:opacity-50"
        >
          Continue
        </CustomLink>
      </div>
    </div>
  );
};

const SelectQuantity: React.FC = () => {
  const { selectingItemFor, setQuantity, currentSelection } = useExchange();

  useEffect(() => {
    if (selectingItemFor?.quantity === 1) {
      setQuantity(1);
    }
  }, [selectingItemFor, setQuantity]);

  const options = range(1, (selectingItemFor?.quantity ?? 0) + 1, 1);

  if (selectingItemFor?.quantity === 1) {
    return <Redirect to={Routes.ExchangeType} />;
  }

  return (
    <div>
      <div className="py-4 text-center">
        How many would you like to exchange?
      </div>

      <select
        className="w-full px-4 py-3 rounded-lg"
        onChange={(e) => {
          if (e.target.value !== "") {
            setQuantity(parseInt(e.target.value, 10));
          }
        }}
        value={currentSelection?.quantity ?? ""}
      >
        <option value="">--</option>
        {options?.map((option) => (
          <option key={`opt-${option}`}>{option}</option>
        ))}
      </select>
      <div className="w-full flex">
        <CustomLink
          disabled={currentSelection?.quantity == null}
          to={Routes.ExchangeType}
          className="flex-1 py-4 text-center active:opacity-50"
        >
          Continue
        </CustomLink>
      </div>
    </div>
  );
};

const SelectExchangeType: React.FC = () => {
  const { selectingItemFor, setProduct } = useExchange();
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const onSelectDifferentSize = async () => {
    setloading(true);
    try {
      const resp = await axios.get<Product>(
        `/v1/products/${selectingItemFor?.product_id}`
      );

      setProduct(resp.data);
      setloading(false);
      history.push(Routes.SelectVariant);
    } catch (err) {
      setloading(false);
    }
  };

  if (loading) {
    return <Loading message="Fetching Product Details" />;
  }

  return (
    <div>
      <div className="py-4 text-center">
        What would you like to exchange for?
      </div>
      <div className="flex flex-col border-t border-gray-300">
        <button
          onClick={onSelectDifferentSize}
          className="border-b border-gray-300 py-2 active:opacity-50 cursor-pointer text-center"
        >
          Different Size
        </button>
        <Link
          to={Routes.SelectCollection}
          className="border-b border-gray-300 py-2 active:opacity-50 cursor-pointer text-center"
        >
          Different Product
        </Link>
      </div>
    </div>
  );
};

const SelectCollection: React.FC = () => {
  const [{ data, loading, error }, refetch] = useAxios({
    url: "/v1/collections",
  });

  if (loading) {
    return <Loading message="Loading Collections" />;
  }

  if (error) {
    return (
      <div className="my-6">
        <div className="text-brand font-semibold text-center">
          Unable to load collections
        </div>
        <button onClick={() => refetch()} className="w-full text-blue-500 mt-8">
          Try again
        </button>
      </div>
    );
  }

  return (
    <div className="">
      <div className="py-4 text-center">Which collection?</div>
      <div className="flex flex-col border-t border-gray-300">
        {data?.map((collection: any) => (
          <Link
            key={collection?.id}
            to={{ pathname: Routes.SelectProduct, state: { collection } }}
            className="border-b border-gray-300 flex flex-row py-2 active:opacity-50 cursor-pointer"
          >
            {collection?.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

const SelectVariant: React.FC = () => {
  const { setVariant, currentSelection } = useExchange();

  const [options, setoptions] = useState<Record<string, string>>(
    currentSelection?.product?.options?.reduce((acc, o) => {
      acc[`option${o?.position ?? "0"}`] = "";
      return acc;
    }, {} as Record<string, string>) ?? {}
  );
  const selectedVariant = useMemo(() => {
    return (
      currentSelection?.product?.variants?.find((variant) => {
        const keys = Object.keys(options);
        const matches = keys.filter((key) => variant[key] === options?.[key]);

        return keys.length === matches.length;
      }) ?? null
    );
  }, [options, currentSelection]);

  useEffect(() => {
    setVariant(selectedVariant);
  }, [setVariant, selectedVariant]);

  return (
    <div>
      <div className="py-4 text-center">Which options would you like?</div>

      {currentSelection?.product?.options?.map((option) => (
        <React.Fragment key={option?.id}>
          <div className="text-brand my-2 font-semibold">{option?.name}</div>
          <select
            className="w-full px-4 py-3 rounded-lg"
            onChange={(e) =>
              setoptions({
                ...options,
                [`option${option.position}`]: e.target.value,
              })
            }
          >
            <option value="">--</option>
            {option?.values?.map((value) => (
              <option key={`${option?.id}-${value}`}>{value}</option>
            ))}
          </select>
        </React.Fragment>
      ))}

      <div className="w-full flex">
        <CustomLink
          disabled={currentSelection?.variant == null}
          to={Routes.ListOrderItems}
          className="flex-1 py-4 text-center active:opacity-50"
        >
          Continue
        </CustomLink>
      </div>
    </div>
  );
};

const SelectExchangeItem = () => {
  const history = useHistory();
  const { selectingItemFor } = useExchange();

  return (
    <div>
      <button
        onClick={() => history.goBack()}
        className="text-blue-500 flex flex-row items-center text-sm active:opacity-50"
      >
        <span className="mr-1">
          <FontAwesomeIcon icon={faArrowCircleLeft} />
        </span>
        Back
      </button>
      <div className="flex flex-col items-center">
        <div className="text-sm text-center">Exchanging</div>
        <div className="font-semibold text-sm text-center">{`${selectingItemFor?.title} ${selectingItemFor?.variant_title}`}</div>
      </div>

      <Switch>
        <Route path={Routes.SelectQuantity} component={SelectQuantity} />
        <Route path={Routes.ExchangeType} component={SelectExchangeType} />
        <Route path={Routes.SelectCollection} component={SelectCollection} />
        <Route path={Routes.SelectProduct} component={SelectProduct} />
        <Route path={Routes.SelectVariant} component={SelectVariant} />

        <Redirect to={Routes.SelectQuantity} />
      </Switch>
    </div>
  );
};

const Exchanging: React.FC<{ lineItemId?: number }> = ({ lineItemId }) => {
  const { selections } = useExchange();
  const isExchanging =
    !!(
      lineItemId &&
      selections?.[lineItemId]?.variant?.id &&
      selections?.[lineItemId]?.quantity
    ) || false;

  if (!isExchanging || !lineItemId) {
    return null;
  }

  return (
    <div className="bg-gray-200 py-2 relative">
      <div className="text-xs text-brand font-semibold absolute right-0 top-0 px-2 py-1">
        <FontAwesomeIcon icon={faStar} className="text-brand" size="xs" />
      </div>
      <div className="flex flex-row">
        <div className="w-1/6 flex items-center justify-center">
          <div className="text-xs">{`${selections?.[lineItemId]?.quantity} x`}</div>
        </div>
        <div className="flex-1">
          <div className="text-xs">
            {selections?.[lineItemId]?.product?.title}
          </div>
          <div className="text-xs">
            {selections?.[lineItemId]?.variant?.title}
          </div>
        </div>
      </div>
    </div>
  );
};

const ListOrderItems = () => {
  const {
    getExchangeQuery,
    setSelectingItemFor,
    selections,
    hasMadeSelections,
    reset,
  } = useExchange();
  const history = useHistory();
  const [isSubmitting, setisSubmitting] = useState(false);
  const [isSubmittedSuccessfully, setisSubmittedSuccessfully] = useState(false);
  const select = (lineItem: LineItemsEntity) => {
    setSelectingItemFor(lineItem);

    history.push(Routes.SelectExchangeItem);
  };

  const submit = async () => {
    const id = getExchangeQuery?.data?.exchange?.id;
    const items = getExchangeQuery?.data?.original_order?.line_items
      ?.map((li) => {
        if (!selections?.[li.id]?.variant) {
          return null;
        }

        return {
          receivedItem: {
            variantId: li.variant_id,
            quantity: li.quantity,
          },
          exchangeFor: {
            variantId: selections?.[li.id]?.variant?.id,
            quantity: selections?.[li.id]?.quantity,
          },
        };
      })
      .filter((item) => item !== null);

    setisSubmitting(true);
    try {
      await axios.put(`/v1/exchanges/${id}`, { id, items });
      setisSubmitting(false);
      setisSubmittedSuccessfully(true);
    } catch (err) {
      setisSubmitting(false);
      setisSubmittedSuccessfully(false);
      toast.error(err);
    }
  };

  if (isSubmittedSuccessfully) {
    return <Redirect to={Routes.ExchangeComplete} />;
  }

  if (getExchangeQuery?.data?.exchange?.status !== "PENDING") {
    return (
      <div className="p-2 flex flex-col items-center justify-start">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="text-brand my-4"
          size="4x"
        />

        <div className="font-semibold text-center">
          An exchange for this order has already been submitted. Email our
          support at{" "}
          <a className="text-brand underline" href="info@dearborndenim.com">
            info@dearborndenim.com
          </a>
        </div>

        <button
          onClick={() => {
            reset();
            window.location.href = "/";
          }}
          className="bg-brand disabled:bg-gray-300 text-white font-semibold px-2 py-3 rounded-lg w-full mt-4 text-center"
        >
          Start over
        </button>
      </div>
    );
  }

  return (
    <div>
      <div className="text-xl text-center my-6">
        What would you like to exchange?
      </div>

      <div className="border-t border-gray-300">
        {getExchangeQuery?.data?.original_order?.line_items?.map((lineItem) => {
          return (
            <div
              key={lineItem?.id}
              onClick={() => select(lineItem)}
              className="border-b border-gray-300 py-2 active:opacity-50 cursor-pointer"
            >
              <div className="flex flex-row">
                <div className="w-1/6 flex items-center justify-center">
                  <div>{`${lineItem?.quantity} x`}</div>
                </div>
                <div className="w-5/6 pr-2">
                  <div className="text-sm">{lineItem?.title}</div>
                  <div className="">{lineItem?.variant_title}</div>
                </div>
              </div>
              <Exchanging lineItemId={lineItem?.id} />
            </div>
          );
        })}
      </div>

      <button
        disabled={hasMadeSelections === false}
        className="bg-brand disabled:bg-gray-300 text-white font-semibold px-2 py-3 rounded-lg w-full mt-4"
        onClick={submit}
      >
        {isSubmitting ? <ButtonLoading /> : "Submit"}
      </button>
    </div>
  );
};

const Exchange = () => {
  const { reset } = useExchange();
  return (
    <div className="p-2">
      <Switch>
        <Route path={Routes.ListOrderItems} component={ListOrderItems} />
        <Route
          path={Routes.SelectExchangeItem}
          component={SelectExchangeItem}
        />
        <Route path={Routes.ExchangeComplete} component={ExchangeComplete} />

        <Redirect to={Routes.ListOrderItems} />
      </Switch>

      <div className="flex justify-center mt-6">
        <button
          className="text-gray-500 text-xs  rounded-lg  mt-4 text-center"
          onClick={() => {
            reset();
            window.location.href = "/";
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export { Exchange as default };
