import React from "react";
import { useEffect } from "react";
import Loading from "components/Loading";
import OrderInfoForm from "components/OrderInfoForm";
import Exchange from "components/Exchange";
import { useExchange } from "components/Exchange/context";
import { ToastContainer, Slide } from "react-toastify";
import styled from "styled-components";
import { useSearchParam } from "react-use";

const StyledToastContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    border-radius: 0;
  }
  .Toastify__toast--error {
    background-color: #ee383a;
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

const useEventListener = <T,>(cb: (event: MessageEvent<T>) => void) => {
  useEffect(() => {
    window.addEventListener("message", cb);

    return () => window.removeEventListener("message", cb);
  }, [cb]);
};

type PostMessageData = {
  type: "SET_EXCHANGE_REQUEST_KEY";
  key: string | null;
};

const App = () => {
  const { getExchangeQuery, setKey, reset } = useExchange();
  const key = useSearchParam("key");
  useEventListener<PostMessageData>((event) => {
    if (event?.data?.type === "SET_EXCHANGE_REQUEST_KEY") {
      setKey(event?.data?.key);
    }
  });

  useEffect(() => {
    if (key !== null) {
      setKey(key);
    }
  }, [setKey, key]);

  if (getExchangeQuery?.loading) {
    return <Loading message="Fetching Order Details" />;
  }

  if (getExchangeQuery?.error) {
    return (
      <div className="p-2 flex flex-col">
        <div className="font-semibold text-center my-16">
          We weren't able to find your order.
        </div>
        <a
          href="/"
          className="bg-brand disabled:bg-gray-300 text-white font-semibold px-2 py-3 rounded-lg w-full mt-4 text-center"
          onClick={() => reset()}
        >
          Try Again
        </a>
      </div>
    );
  }

  if (!getExchangeQuery?.data) {
    return <OrderInfoForm />;
  }

  return <Exchange />;
};

const Container = () => {
  return (
    <>
      <App />
      <StyledToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        transition={Slide}
      />
    </>
  );
};

export { Container as default };
