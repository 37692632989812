import React from "react";

import styled from "styled-components";

const _Loading: React.FC<{ message: string; className?: string }> = (props) => (
  <div className={props.className}>
    <div className="LdsGrid">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    <div className="Message">{props.message}</div>
  </div>
);

const Loading = styled(_Loading)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;

  .Message {
    margin-top: 15px;
    font-size: 1.1em;
  }

  > .LdsGrid {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    div {
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #ee383a;
      animation: LdsGrid 1.2s linear infinite;

      &:nth-child(1) {
        top: 6px;
        left: 6px;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        top: 6px;
        left: 26px;
        animation-delay: -0.4s;
      }
      &:nth-child(3) {
        top: 6px;
        left: 45px;
        animation-delay: -0.8s;
      }
      &:nth-child(4) {
        top: 26px;
        left: 6px;
        animation-delay: -0.4s;
      }
      &:nth-child(5) {
        top: 26px;
        left: 26px;
        animation-delay: -0.8s;
      }
      &:nth-child(6) {
        top: 26px;
        left: 45px;
        animation-delay: -1.2s;
      }
      &:nth-child(7) {
        top: 45px;
        left: 6px;
        animation-delay: -0.8s;
      }
      &:nth-child(8) {
        top: 45px;
        left: 26px;
        animation-delay: -1.2s;
      }
      &:nth-child(9) {
        top: 45px;
        left: 45px;
        animation-delay: -1.6s;
      }
    }
  }

  @keyframes LdsGrid {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`;

export const Spinner = styled.div`
  @keyframes pulse {
    0% {
      opacity: 0.2;
    }

    20% {
      opacity: 0.9;
    }

    100% {
      opacity: 0.2;
    }
  }

  span {
    animation-name: pulse;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
`;

interface ButtonLoadingProps {
  className?: string;
}

const ButtonLoading: React.FC<ButtonLoadingProps> = ({ className = "" }) => (
  <Spinner className={className}>
    <span>• </span>
    <span>• </span>
    <span>•</span>
  </Spinner>
);

export { Loading as default, ButtonLoading };
