import React, {
  useMemo,
  createContext,
  PropsWithChildren,
  useContext,
  Dispatch,
  useEffect,
} from "react";
import { AxiosError } from "axios";
import { ResponseValues } from "axios-hooks";
import { useAxios } from "hooks/axios";
import {
  LineItemsEntity,
  GetExchangeResponse,
  Product,
  VariantsEntity,
} from "./types";
import { useCallback } from "react";
import { useImmerReducer } from "use-immer";

interface IState {
  key: string | null;
  selections: Record<number, With> | null;
  selectingItemFor: LineItemsEntity | null;
}

interface IContext {
  getExchangeQuery: ResponseValues<GetExchangeResponse, AxiosError<any>> | null;

  state: IState;
  dispatch: Dispatch<DispatcherAction>;
}

enum Actions {
  InitLineItems = "INIT_LINE_ITEMS",
  SetSelectingItemFor = "SET_SELECTING_ITEM_FOR",
  SetQuantityForExchange = "SET_QUANTITY_FOR_EXCHANGE",
  SetProductForExchange = "SET_PRODUCT_FOR_EXCHANGE",
  SetVariantForExchange = "SET_VARIANT_FOR_EXCHANGE",
  SetKey = "SET_KEY",
  Reset = "RESET",
}

interface With {
  product: Product | null;
  variant: VariantsEntity | null;
  quantity: number | null;
}

type InitLineItems = {
  type: Actions.InitLineItems;
  data: Record<number, With>;
};

type SetSelectingItemFor = {
  type: Actions.SetSelectingItemFor;
  data: LineItemsEntity | null;
};

type SetQuantityForExchange = {
  type: Actions.SetQuantityForExchange;
  data: number;
};

type SetProductForExchange = {
  type: Actions.SetProductForExchange;
  data: Product | null;
};

type SetVariantForExchange = {
  type: Actions.SetVariantForExchange;
  data: VariantsEntity | null;
};

type SetKey = {
  type: Actions.SetKey;
  data: string | null;
};

type Reset = {
  type: Actions.Reset;
};

type DispatcherAction =
  | InitLineItems
  | SetKey
  | SetSelectingItemFor
  | SetQuantityForExchange
  | SetProductForExchange
  | SetVariantForExchange
  | Reset;

const initialState: IState = {
  key: null,
  selections: null,
  selectingItemFor: null,
};

const ctx = createContext<IContext>({
  getExchangeQuery: null,

  state: initialState,
  dispatch: () => null,
});

export const ExchangeProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [getExchangeQuery, getExchange] = useAxios<GetExchangeResponse>(
    {},
    { manual: true, useCache: true }
  );

  const [state, dispatch] = useImmerReducer(
    (state: IState, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.SetKey:
          state.key = action.data;
          break;
        case Actions.InitLineItems:
          state.selections = action.data;
          break;
        case Actions.SetSelectingItemFor:
          state.selectingItemFor = action.data;
          break;
        case Actions.SetQuantityForExchange:
          if (
            state?.selectingItemFor?.id &&
            state?.selections?.[state.selectingItemFor.id]
          ) {
            state.selections[state.selectingItemFor.id].quantity = action.data;
          }

          break;

        case Actions.SetProductForExchange:
          if (
            state?.selectingItemFor?.id &&
            state?.selections?.[state.selectingItemFor.id]
          ) {
            state.selections[state.selectingItemFor.id].product = action.data;
          }
          break;
        case Actions.SetVariantForExchange:
          if (
            state?.selectingItemFor?.id &&
            state?.selections?.[state.selectingItemFor.id]
          ) {
            state.selections[state.selectingItemFor.id].variant = action.data;
          }
          break;
        case Actions.Reset:
          state = initialState;
          break;
        default:
          break;
      }

      return state;
    },
    { ...initialState }
  );

  useEffect(() => {
    if (getExchangeQuery?.data) {
      dispatch({
        type: Actions.InitLineItems,
        data:
          getExchangeQuery?.data?.original_order?.line_items?.reduce(
            (acc, li) => {
              acc[li.id] = { product: null, variant: null, quantity: null };
              return acc;
            },
            {} as Record<number, With>
          ) ?? {},
      });
    }
  }, [dispatch, getExchangeQuery]);

  useEffect(() => {
    const k = state.key;

    if (
      k &&
      !getExchangeQuery?.data &&
      !getExchangeQuery?.loading &&
      !getExchangeQuery?.error
    ) {
      getExchange({ url: `/v1/exchanges/${k}` }).catch((err) =>
        console.error(err)
      );
    }
  }, [state, getExchange, getExchangeQuery]);

  return (
    <ctx.Provider
      value={{
        getExchangeQuery,
        state,
        dispatch,
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export const useExchange = () => {
  const { getExchangeQuery, state, dispatch } = useContext(ctx);

  const setSelectingItemFor = (lineItem: LineItemsEntity | null) =>
    dispatch({ type: Actions.SetSelectingItemFor, data: lineItem });

  const setQuantity = useCallback(
    (quantity: number) =>
      dispatch({ type: Actions.SetQuantityForExchange, data: quantity }),
    [dispatch]
  );

  const setProduct = useCallback(
    (product: Product | null) =>
      dispatch({ type: Actions.SetProductForExchange, data: product }),
    [dispatch]
  );

  const setVariant = useCallback(
    (variant: VariantsEntity | null) =>
      dispatch({ type: Actions.SetVariantForExchange, data: variant }),
    [dispatch]
  );

  const currentSelection = useMemo(
    () =>
      (state.selectingItemFor &&
        state.selectingItemFor?.id !== 0 &&
        state.selections?.[state.selectingItemFor.id]) ||
      null,
    [state]
  );

  const hasMadeSelections = useMemo(() => {
    return (
      (getExchangeQuery?.data?.original_order?.line_items?.filter(
        (li) => state.selections?.[li.id]?.variant !== null
      )?.length ?? 0) > 0
    );
  }, [state, getExchangeQuery]);

  const setKey = (key: string | null) =>
    dispatch({ type: Actions.SetKey, data: key });

  const reset = () => dispatch({ type: Actions.Reset });

  return {
    ...state,
    currentSelection,
    getExchangeQuery,
    hasMadeSelections,
    setSelectingItemFor,
    setQuantity,
    setProduct,
    setVariant,
    setKey,
    reset,
  };
};
